import "./App.css";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

export const Shows = () => {
  const showData = [
    {
      venue: "Crystal Ballroom w/ Franz Fernidand",
      city: "Portland, Oregon",
      date: "12-15-2024",
      tickets:
        "https://www.etix.com/ticket/p/49968213/a-december-to-remember-with-franz-ferdinandall-ages-portland-mcmenamins-crystal-ballroom-portlandor?_gl=1*1aurmm4*_ga*MTgxMDQ3MTEwNi4xNzMyMDQ5MTM5*_ga_BKTLLLLP9L*MTczMjA0OTEzOC4xLjAuMTczMjA0OTEzOC42MC4wLjA.",
    },
    {
      venue: "No Fun Bar",
      city: "Portland, Oregon",
      date: "11-17-2024",
    },
    {
      venue: "Whitman College w/ 60 Juno",
      city: "Walla Walla, Washington",
      date: "11-15-2024",
    },
    {
      venue: "Ethos Music Center",
      city: "Portland, Oregon",
      date: "11-9-2024",
    },
    {
      venue: "Mississippi Studios",
      city: "Portland, Oregon",
      date: "9-21-2024",
      tickets:
        "https://www.etix.com/ticket/p/48204612/les-gold-portland-mississippi-studios?_gl=1*1se6vwv*_ga*MTcwNjU0NzM5Ny4xNzI1NTYzNTA3*_ga_T3044165VM*MTcyNTU2MzUwNi4xLjAuMTcyNTU2MzUwNi42MC4wLjA.&_ga=2.46185639.1281388398.1725563507-1706547397.1725563507",
    },
  ];

  return (
    <Grid container>
      <Grid size={2}></Grid>
      <Grid size={8}>
        <Stack
          mt={10}
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" fontFamily={"CZ-Regular"}>
            Shows
          </Typography>

          <img src="images/IMG_4912.jpg" alt="Les Gold" width={"40%"} />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ background: "#c1ddc7" }}>
                  <TableCell>Venue</TableCell>
                  <TableCell align="right">City</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Tickets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showData.map(({ venue, city, date, tickets }, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: i % 2 === 0 ? "#f5e8c6" : "#f4d279",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {venue}
                      </TableCell>
                      <TableCell align="right">{city}</TableCell>
                      <TableCell align="right">{date}</TableCell>
                      <TableCell align="right">
                        {tickets ? (
                          <a href={tickets}>Tickets</a>
                        ) : (
                          "No tickets available"
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Grid>
      <Grid size={2}></Grid>
    </Grid>
  );
};
