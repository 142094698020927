import { HighlightOff } from "@mui/icons-material";
import { Card, Collapse } from "@mui/material";
import Grid from "@mui/material/Grid2";
import _ from "lodash";
import { useEffect, useState } from "react";

const randomCoordinates = (n: number, multiplier: number, noise: number) => {
  const coords = [];
  for (let i = 0; i < n; i++) {
    coords.push(i * multiplier + Math.random() * noise);
  }
  return _.shuffle(coords);
};

export const Home = () => {
  const polaroids = [
    "polaroids/img20240915_18272840.jpg",
    "polaroids/img20240915_18285294.jpg",
    "polaroids/img20240915_18295920.jpg",
    "polaroids/img20240915_18310372.jpg",
    "polaroids/img20240915_18320161.jpg",
    "polaroids/img20240915_18345599.jpg",
    "polaroids/img20240915_18432215.jpg",
    "polaroids/img20240915_18582054.jpg",
  ];

  const { innerWidth: width, innerHeight: height } = window;

  const [topCoords, setTopCoords] = useState(
    randomCoordinates(polaroids.length, height / polaroids.length / 2, 100),
  );
  const [leftCoords, setLeftCoords] = useState(
    randomCoordinates(polaroids.length, width / polaroids.length / 1.2, 100),
  );

  console.log(topCoords, leftCoords);

  const minTop = 200;
  const minLeft = 100;
  const period = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      setTopCoords(
        randomCoordinates(polaroids.length, height / polaroids.length / 2, 100),
      );
      setLeftCoords(
        randomCoordinates(polaroids.length, width / polaroids.length, 100),
      );
    }, period);
    return () => {
      clearInterval(interval);
    };
  }, [height, width, polaroids.length]);

  const [clickedImage, setClickedImage] = useState("");

  return (
    <Grid
      container
      size={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // background: `url(${process.env.PUBLIC_URL}/bright-light-black-background.jpg)`,
        // height: "1000vh",
        // width: "100%",
        backgroundSize: "cover",
      }}
    >
      <Grid size={12} mt={15}>
        {polaroids.map((polaroid, i) => (
          <Grid size={2}>
            <img
              key={polaroid}
              src={polaroid}
              alt="Les Gold"
              width={"20%"}
              onClick={() => {
                setClickedImage(polaroid);
              }}
              style={{
                display: "block",
                position: "absolute",
                top: minTop + topCoords[i],
                left: Math.min(leftCoords[i], width),
                transform: `rotate(${(Math.random() - 0.5) * 50}deg)`,
                transition: "all 5s",
                border: "1px solid black",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Collapse in={clickedImage !== ""}>
        <Card
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 99,
          }}
        >
          <HighlightOff
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
              color: "white",
            }}
            fontSize="large"
            onClick={() => {
              setClickedImage("");
            }}
          />
          <img
            src={clickedImage}
            alt="Les Gold"
            width={"40%"}
            style={{
              display: "block",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 100,
              border: "1px solid black",
            }}
            onClick={() => {
              setClickedImage("");
            }}
          />
        </Card>
      </Collapse>
    </Grid>
  );
};
