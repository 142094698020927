import "./App.css";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export const Contact = () => {
  return (
    <Grid size={12}>
      <Stack
        mt={10}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" fontFamily={"CZ-Regular"}>
          Contact
        </Typography>
        <img
          src="https://instagram.fhio2-1.fna.fbcdn.net/v/t39.30808-6/462716950_18325528540159916_1753248538022259649_n.jpg?stp=dst-jpg_e35_p1080x1080_sh0.08_tt6&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyLmYzMDgwOC5kZWZhdWx0X2ltYWdlIn0&_nc_ht=instagram.fhio2-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=OtAc6eW5U3cQ7kNvgE2vNRR&_nc_gid=090b085536a4497596740b3c0e4ec84f&edm=ANTKIIoAAAAA&ccb=7-5&oh=00_AYBe_v_wlgp1Zy-Wo5BQ_hIQKdljFhn5gtX7NCalKvafjA&oe=6742E4F9&_nc_sid=d885a2"
          alt="Les Gold"
          width={"20%"}
        />
        <Typography variant="h6">Management: Maddy Yen</Typography>
        <Typography variant="h6">
          Email: Maddy Yen{" - "}
          <a href={`mailto:maddyyen@outlook.com?subject=LesGold`}>
            {" "}
            maddyyen@outlook.com{" "}
          </a>
        </Typography>
        <Typography variant="h6">
          Phone: Maddy Yen{" - "}
          <a href={`tel:408-605-5776`}> 408-605-5776 </a>
        </Typography>
      </Stack>
    </Grid>
  );
};
