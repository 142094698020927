import "./App.css";
import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";

export const Header = () => {
  const { innerWidth: width } = window;
  return (
    <Grid
      container
      size={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,
        height: "80px",
      }}
    >
      <Grid
        size={2}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Link to="/about" style={{ textDecoration: "none", color: "grey" }}>
          <Typography variant="h6" fontFamily={"CZ-Regular"}>
            About
          </Typography>
        </Link>
        <Link to="/contact" style={{ textDecoration: "none", color: "grey" }}>
          <Typography variant="h6" fontFamily={"CZ-Regular"}>
            Contact
          </Typography>
        </Link>
      </Grid>
      <Grid
        size={8}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Stack>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography
              variant={width > 1200 ? "h3" : "h5"}
              fontFamily={"CZ-Regular"}
              color="gold"
            >
              Les Gold
            </Typography>
          </Link>
          <Grid
            container
            mt={3}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              component="a"
              href="https://open.spotify.com/artist/3XfOlbu8GzAWZgunWPap5s"
            >
              <img
                src="https://storage.googleapis.com/pr-newsroom-wp/1/2023/05/Spotify_Primary_Logo_RGB_Black.png"
                alt="Spotify"
                width={width > 1200 ? "25vw" : "15vw"}
              />
            </Button>

            <Button
              component="a"
              href="https://www.youtube.com/channel/UCCHQ9PnilyXKIIEAA89XhDw"
            >
              <img
                src="images/yt_logo_mono_light.png"
                alt="Youtube"
                width={width > 1200 ? "75vw" : "50vw"}
              />
            </Button>

            <Button component="a" href="https://instagram.com/lesgoldmusic">
              <img
                src="images/Instagram_Glyph_Black.jpg"
                alt="Instgram"
                width={width > 1200 ? "25vw" : "15vw"}
              />
            </Button>
          </Grid>
        </Stack>
      </Grid>
      <Grid
        size={2}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Link to="/shows" style={{ textDecoration: "none", color: "grey" }}>
          <Typography variant="h6" fontFamily={"CZ-Regular"}>
            Shows
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
